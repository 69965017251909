// MENU BUTTON & SIDEBAR
const menu = document.querySelector(".menu");
menu.addEventListener("click", toggleMenu);

// Toggle bar on menu button click
function toggleMenu() {
  // Menu bars change from hamburger to cross
  const menuBars = document.querySelectorAll(".menu__bar");

  menuBars.forEach((bar) => {
    bar.classList.toggle("change");
  });

  // Show sidebar
  const sidebar = document.querySelector(".sidebar");
  const sidebarItems = document.querySelectorAll(".sidebar__item");

  sidebar.classList.toggle("change");
  sidebarItems.forEach((item) => {
    item.classList.toggle("change");
  });

  //  Make logo appear if it is hidden
  const logo = document.querySelector("#header__logo");
  if (logo.style.visibility === "hidden") {
    logo.style.opacity = "1";
    logo.style.visibility = "visible";
  }
}

// Hide sidebar on sidebar__item link click
const sidebarLinks = document.querySelectorAll(".sidebar__link");
sidebarLinks.forEach((link) => {
  link.addEventListener("click", toggleMenu);
});

// HEADER
let prevScrollPosition = window.scrollY;

// Hide header on scroll
/*window.onscroll = function () {
  const logo = document.querySelector("#header__logo");
  let { scrollY, innerHeight } = window;

  if (scrollY <= innerHeight * 0.1 || scrollY < prevScrollPosition) {
    logo.style.opacity = "1";
    logo.style.visibility = "visible";
  } else {
    logo.style.opacity = "0";
    logo.style.visibility = "hidden";
  }

  /!* Hide the logo if the user scrolls beyond the third section, it avoids the
   logo appears over the Contact section *!/
  const { clientHeight } = document.querySelector("main");
  if (scrollY > clientHeight * 0.95) {
    logo.style.opacity = "0";
    logo.style.visibility = "hidden";
  }

  prevScrollPosition = scrollY;
};*/

// COMPLEMENTARY INFORMATION
const showcaseButtons = document.querySelectorAll(".showcase__button");
showcaseButtons.forEach((button) => {
  button.addEventListener("click", toggleArticleDetails);
});

function toggleArticleDetails(event) {
  const article = event.target.closest(".article");
  const articleId = article.id;

  const button = event.target;
  let buttonText = button.innerHTML.toLowerCase().trim();
  const isXButton = button.classList.value.includes("showcase__button--close");

  if (isXButton) {
    const showcaseButton = document.querySelector(`#${article.id}-button`);
    let showcaseButtonText = showcaseButton.innerHTML.toLowerCase();

    if (showcaseButtonText === "see details") {
      showcaseButton.innerHTML = "Hide details";
    } else if (showcaseButtonText === "hide details") {
      showcaseButton.innerHTML = "See details";
    }
  }

  if (buttonText === "see details" && !isXButton) {
    button.innerHTML = "Hide details";
  } else if (buttonText === "hide details") {
    button.innerHTML = "See details";
  }

  const showcase = article.childNodes[1]; // article__showcase
  showcase.classList.toggle("change");

  const details = document.querySelector(`[data-id=${articleId}]`);
  details.classList.toggle("change");

  window.location.href = `#${articleId}`;
}

// FORCE VIDEO PLAY
function playShowcaseVideos() {
  const showcaseVideos = document.querySelectorAll("video.showcase__video");
  showcaseVideos.forEach((video) => {
    if (video.paused) {
      try {
        video.play();
      } catch (e) {
        console.error(e);
      }
    }
  });
}

document.addEventListener(
  "DOMContentLoaded",
  () => {
    setTimeout(() => {
      playShowcaseVideos();
    }, 2000);
  },
  false
);
